import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactBlockComponent implements OnInit {
  @Input() contact: any;
  
  constructor() { }

  ngOnInit() {
  }

}
